// resources/js/composables/useBugsnagUser.js

import { ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Bugsnag from '@bugsnag/js';

// Create a flag outside the composable to track initialization
let isInitialized = false;

/**
 * useBugsnagUser Composable
 *
 * Manages setting the Bugsnag user based on authentication state.
 */
export default function useBugsnagUser() {
    // Prevent multiple initializations
    if (isInitialized) {
        return;
    }
    isInitialized = true;

    const page = usePage();
    const { auth } = page.props;
    const user = auth?.user;

    // Reactive flag to track if Bugsnag user is set
    const isBugsnagUserSet = ref(false);

    // Function to set Bugsnag user
    const setBugsnagUser = (user) => {
        if (user && !isBugsnagUserSet.value) {
            Bugsnag.setUser(user.id, user.email, user.full_name);
            isBugsnagUserSet.value = true;
        }
    };

    // Function to clear Bugsnag user
    const clearBugsnagUser = () => {
        if (isBugsnagUserSet.value) {
            Bugsnag.setUser(); // Clears the user information
            isBugsnagUserSet.value = false;
        }
    };

    // Initial setting of Bugsnag user
    setBugsnagUser(user);

    // Watch for changes in the authenticated user
    watch(
        () => page.props.auth.user,
        (newUser, oldUser) => {
            if (newUser && !isBugsnagUserSet.value) {
                setBugsnagUser(newUser);
            } else if (!newUser && isBugsnagUserSet.value) {
                clearBugsnagUser();
            } else if (newUser && isBugsnagUserSet.value && oldUser && newUser.id !== oldUser.id) {
                // If user changes, update Bugsnag
                setBugsnagUser(newUser);
            }
        }
    );

    return {
        isBugsnagUserSet,
        setBugsnagUser,
        clearBugsnagUser,
    };
}
